/*** FONTS ***/

/* Poppins FONT*/

@font-face {
    font-family: "Poppins-Bold";
    src: url("../fonts/poppins/Poppins-Bold.otf");
    src:
        local("Poppins Bold"),
        local("Poppins-Bold"),
        url("../fonts/poppins/Poppins-Bold.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-BoldItalic";
    src: url("../fonts/poppins/Poppins-BoldItalic.otf");
    src:
        local("Poppins BoldItalic"),
        local("Poppins-BoldItalic"),
        url("../fonts/poppins/Poppins-BoldItalic.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/poppins/Poppins-Italic.otf");
    src:
        local("Poppins Italic"),
        local("Poppins-Italic"),
        url("../fonts/poppins/Poppins-Italic.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/poppins/Poppins-Italic.otf");
    src:
        local("Poppins Italic"),
        local("Poppins-Italic"),
        url("../fonts/poppins/Poppins-Italic.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-ExtraLight";
    src: url("../fonts/poppins/Poppins-ExtraLight.otf");
    src:
        local("Poppins Extra Light"),
        local("Poppins-ExtraLight"),
        url("../fonts/poppins/Poppins-ExtraLight.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-Light";
    src: url("../fonts/poppins/Poppins-Light.otf");
    src:
        local("Poppins Light"),
        local("Poppins-Light"),
        url("../fonts/poppins/Poppins-Light.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-Medium";
    src: url("../fonts/poppins/Poppins-Medium.otf");
    src:
        local("Poppins Medium"),
        local("Poppins-Medium"),
        url("../fonts/poppins/Poppins-Medium.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-MediumItalic";
    src: url("../fonts/poppins/Poppins-MediumItalic.otf");
    src:
        local("Poppins Medium Italic"),
        local("Poppins-MediumItalic"),
        url("../fonts/poppins/Poppins-MediumItalic.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-Regular";
    src: url("../fonts/poppins/Poppins-Regular.otf");
    src:
        local("Poppins Regular"),
        local("Poppins-Regular"),
        url("../fonts/poppins/Poppins-Regular.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-SemiBold";
    src: url("../fonts/poppins/Poppins-SemiBold.otf");
    src:
        local("Poppins SemiBold"),
        local("Poppins-SemiBold"),
        url("../fonts/poppins/Poppins-SemiBold.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-ExtraBold";
    src: url("../fonts/poppins/Poppins-ExtraBold.otf");
    src:
        local("Poppins ExtraBold"),
        local("Poppins-ExtraBold"),
        url("../fonts/poppins/Poppins-ExtraBold.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-ExtraBoldItalic";
    src: url("../fonts/poppins/Poppins-ExtraBoldItalic.otf");
    src:
        local("Poppins ExtraBoldItalic"),
        local("Poppins-ExtraBoldItalic"),
        url("../fonts/poppins/Poppins-ExtraBoldItalic.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-Black";
    src: url("../fonts/poppins/Poppins-Black.otf");
    src:
        local("Poppins Black"),
        local("Poppins-Black"),
        url("../fonts/poppins/Poppins-Black.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-BlackItalic";
    src: url("../fonts/poppins/Poppins-BlackItalic.otf");
    src:
        local("Poppins Black Italic"),
        local("Poppins-BlackItalic"),
        url("../fonts/poppins/Poppins-BlackItalic.otf") format("opentype");
}

/*** Variables ***/

$color-one: #050505;
$color-two: #ffffff;
$color-three: #7544ef;
$color-four: #441556;
$color-five: #fd45d9;
$color-six: #2ad5de;
$color-seven: #cc1616;
$color-eight: #29b77b;
$color-nine: #51328b;
$color-welcome: #2f2b31;

/* Fonts variable */

$poppins-bold: Poppins-Bold, Arial, Helvetica, sans-serif;
$poppins-extrabold: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
$poppins-extralight: Poppins-ExtraLight, Arial, Helvetica, sans-serif;
$poppins-light: Poppins-Light, Arial, Helvetica, sans-serif;
$poppins-medium: Poppins-Medium, Arial, Helvetica, sans-serif;
$poppins-medium-italic: Poppins-MediumItalic, Arial, Helvetica, sans-serif;
$poppins-regular: Poppins-Regular, Arial, Helvetica, sans-serif;
$poppins-semibold: Poppins-SemiBold, Arial, Helvetica, sans-serif;
$poppins-bold-italic: Poppins-BoldItalic, Arial, Helvetica, sans-serif;
$poppins-extrabold-italic: Poppins-ExtraBoldItalic, Arial, Helvetica, sans-serif;
$poppins-black: Poppins-Black, Arial, Helvetica, sans-serif;
$poppins-black-italic: Poppins-BlackItalic, Arial, Helvetica, sans-serif;

/*** Poppins ***/

.poppins-bold-font {
    font-family: $poppins-bold;
}

.poppins-extrabold-font {
    font-family: $poppins-extrabold;
}

.poppins-extralight-font {
    font-family: $poppins-extralight;
}

.poppins-light-font {
    font-family: $poppins-light;
}

.poppins-medium-font {
    font-family: $poppins-medium;
}

.poppins-medium-italic-font {
    font-family: $poppins-medium-italic;
}

.poppins-regular-font {
    font-family: $poppins-regular;
}

.poppins-semibold-font {
    font-family: $poppins-semibold;
}

.poppins-bold-italic-font {
    font-family: $poppins-bold-italic;
}

.poppins-extrabold-italic-font {
    font-family: $poppins-extrabold-italic;
}

.poppins-black-font {
    font-family: $poppins-black;
}

.poppins-black-italic-font {
    font-family: $poppins-black-italic;
}

/* Font size variables */

.font-size-10 {
    font-size: 10px;
}

.font-size-12 {
    font-size: 12px;
}

.font-size-14 {
    font-size: 14px;
}

.font-size-15 {
    font-size: 15px;
}

.font-size-16 {
    font-size: 16px;
}

.font-size-18 {
    font-size: 18px;
}

.font-size-20 {
    font-size: 20px;
}

.font-size-22 {
    font-size: 22px;
}

.font-size-24 {
    font-size: 24px;
}

.font-size-25 {
    font-size: 25px;
}

.font-size-26 {
    font-size: 26px;
}

.font-size-28 {
    font-size: 28px;
}

.font-size-30 {
    font-size: 30px;
}

.font-size-32 {
    font-size: 32px;
}

.font-size-34 {
    font-size: 34px;
}

.font-size-36 {
    font-size: 36px;
}

.font-size-38 {
    font-size: 38px;
}

.font-size-40 {
    font-size: 40px;
}

.font-size-45 {
    font-size: 45px;
}

.font-size-50 {
    font-size: 50px;
}

.font-size-55 {
    font-size: 55px;
}

.font-size-60 {
    font-size: 60px;
}

.font-size-65 {
    font-size: 65px;
}

.font-size-70 {
    font-size: 70px;
}

.font-size-75 {
    font-size: 75px;
}

.font-size-80 {
    font-size: 80px;
}

.font-size-85 {
    font-size: 85px;
}

.font-size-90 {
    font-size: 90px;
}

.font-size-95 {
    font-size: 95px;
}

.font-size-100 {
    font-size: 100px;
}

.font-size-120 {
    font-size: 120px;
}

.font-size-150 {
    font-size: 150px;
}

/*** Márgenes ***/

.m-0 {
    margin: 0 auto !important;
}
.m-t-0 {
    margin-top: 0 !important;
}
.m-t-5 {
    margin-top: 5px !important;
}
.m-t-10 {
    margin-top: 10px !important;
}
.m-t-20 {
    margin-top: 20px !important;
}
.m-t-30 {
    margin-top: 30px !important;
}
.m-t-40 {
    margin-top: 40px !important;
}
.m-b-0 {
    margin-bottom: 0px !important;
}
.m-b-5 {
    margin-bottom: 5px !important;
}
.m-b-10 {
    margin-bottom: 10px !important;
}
.m-b-20 {
    margin-bottom: 20px !important;
}
.m-b-30 {
    margin-bottom: 30px !important;
}
.m-b-40 {
    margin-bottom: 40px !important;
}
.m-tb-15 {
    margin: 15px 0 !important;
}
.m-tb-20 {
    margin: 20px 0 !important;
}
.m-tb-40 {
    margin: 40px 0 !important;
}
.p-t-0 {
    padding-top: 0 !important;
}
.p-t-10 {
    padding-top: 10px;
}
.p-t-30 {
    padding-top: 30px;
}
.p-t-50 {
    padding-top: 50px;
}
.p-b-10 {
    padding-bottom: 10px !important;
}
.p-b-30 {
    padding-bottom: 30px !important;
}
.p-tb-10 {
    padding: 10px 0 20px;
}
.p-tb-30 {
    padding: 30px 0;
}
.p-r {
    padding-right: 10px;
}
.p-r-20 {
    padding-right: 20px !important;
}
.p-r-30 {
    padding-right: 30px;
}
.p-r-50 {
    padding-right: 50px;
}
.p-l-20 {
    padding-left: 20px;
}
.p-l-30 {
    padding-left: 30px;
}
.lh-26 {
    line-height: 26px;
}
.f-s-24 {
    font-size: 24px !important;
}

.no-bg {
    background-color: transparent !important;
    color: white !important;
}

.right,
.right-data {
    position: absolute;
    right: 0;
    p {
        text-align: right;
    }
}

.block {
    display: block;
}

.flex {
    display: flex;
}

.green {
    color: #29b77b !important;
}

.red {
    color: #cc1616 !important;
}

.purple-font {
    color: $color-nine !important;
}

.container-min-height-500 {
    min-height: 500px;
}

/******/

.line-height-200 {
    line-height: 200%;
}

.display-flex {
    display: flex;
}

.justify-content-center {
    justify-content: center;
}
