@use "content-no-plans-products_config" as config;

.container-content-no-plans-products {
    .img-title-no-plans {
        max-width: 100%;
        max-height: 120px;
        margin: 0 auto;
        @media (min-width: config.$breakpoint-md) {
            max-height: 140px;
        }
    }
}
