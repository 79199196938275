.container-app-bar .image-logo-app-bar {
    width: 170px;
    height: auto;
    vertical-align: middle;
}
.container-app-bar .user {
    background-color: rgba(23, 16, 34, 0.3);
    font-size: 15px;
    border-radius: 10px;
    padding: 0 20px;
    width: 25%;
    display: flex;
    align-items: center;
}
.container-app-bar .user p {
    margin: 10px 0;
}
.container-app-bar .user p span {
    font-weight: bold;
}
.container-app-bar .user .icon {
    display: flex;
    align-items: center;
    align-content: center;
}


