/*** Variables ***/
@use "../variables" as v;
/*** Mixins ***/
@use "../mixins" as m;
/*** Tipografía ***/
@use "../typography.scss" as t;

.error-text,
.required-incidence,
.invalid-feedback {
    font-family: t.$poppins-regular !important;
    font-weight: 500 !important;
    color: red !important;
    font-size: 15px !important;
    &.invalid-feedback {
        margin: 10px 0;
    }
}
