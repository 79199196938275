.container-app-bar {
    .image-logo-app-bar {
        width: 170px;
        height: auto;
        vertical-align: middle;
    }

    .user {
        background-color: rgb(23 16 34 / 30%);
        font-size: 15px;
        border-radius: 10px;
        padding: 0 20px;
        width: 25%;
        display: flex;
        align-items: center;
        p {
            margin: 10px 0;
            span {
                font-weight: bold;
            }
        }
        .icon {
            display: flex;
            align-items: center;
            align-content: center;
        }
    }
}
