.products-component-container {
    @mixin center-loaders {
        position: absolute;
        top: 50%;
        right: 25%;
        left: auto;
    }

    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    padding-bottom: 40px;

    .compatibility-image {
        img {
            height: 450px;
        }
    }

    .compatibility-message {
        text-align: center;
        color: white;
        h1 {
            font-family: "Poppins-ExtraBold";
            font-size: 40px;
        }
        h2 {
            font-family: "Poppins-Bold";
            font-size: 25px;
        }
        p {
            font-size: 18px;
        }
        @media (min-width: 900px) {
            text-align: right;
            h1 {
                font-size: 50px;
            }
            h2 {
                font-size: 30px;
            }
            p {
                font-size: 20px;
            }
        }
    }

    .title {
        text-align: center;
        color: white;
        margin: 20px 0 0;

        h1 {
            font-size: 22px !important;
            font-family: "Poppins-ExtraBold";
            span {
                color: #2ad5de;
            }
        }
        h2 {
            font-size: 30px;
            font-family: "Poppins-Bold";
        }
        @media (min-width: 900px) {
            margin: 25px 0 0;
            h1 {
                font-size: 25px !important;
            }
            h2 {
                font-size: 35px;
            }
        }
    }

    span.MuiCircularProgress-root {
        color: #ffffff !important;
    }

    span.Circular-progress-payment {
        @include center-loaders;
        color: #ff28dd !important;
    }

    span.Circular-progress-product {
        width: 50px;
        height: 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -25px 0 0 -25px;
    }

    span.Circular-progress {
        @include center-loaders;
        color: #ff28dd !important;
    }

    span.Circular-progress-client {
        @include center-loaders;
        color: rgb(255, 255, 255) !important;
    }
}
