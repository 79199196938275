.container-graphic-card h1 {
    font-family: "Poppins-Bold";
}
.container-graphic-card .chart-container {
    font-family: "Poppins-Regular";
}
.container-graphic-card .chart-container .chart h4 {
    font-family: "Poppins-Medium";
}
.container-graphic-card .chart-container .data .colored {
    font-family: "Poppins-Medium";
}


