/*** Variables ***/
@use "../variables" as v;
/*** Mixins ***/
@use "../mixins" as m;

.lead-recovery-wrapper {
    margin: 25px 0;
    align-items: center;
}

.lead-container-congratulations {
    background: transparent linear-gradient(180deg, #302651 0%, #441959 100%) 0%
        0% no-repeat padding-box;
    border-radius: 15px;
}

.lead-recovery-section {
    &.white-bg {
        background: #ffffff;
        border-radius: 20px;
        color: #2f2b31;
    }

    .lead-recovery-column {
        .MuiGrid-root.MuiGrid-item {
            display: flex;
            justify-content: center;
        }
    }

    .first-section {
        padding: 25px 75px 25px 0px;
    }

    .input-container > div > div {
        margin: 0px 0 !important;
    }
    .input-container > div {
        margin: 0px 0 !important;
    }

    .span-word-title {
        background-color: white;
        color: v.$color-four;
        border-radius: 25px;
        padding-left: 10px;
        padding-right: 10px;
    }
}

.compatibility-image {
    max-width: 100%;
    max-height: 450px;
    margin: 0px auto;
}

.input-container {
    margin: 20px 0px !important;
    input:hover {
        border-color: v.$color-four !important;
    }
    label {
        padding: 0px;
        font-weight: 600;
        font-size: 15px;
        color: #5a5a5a;
    }
}

/*********/

.lead-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.lead-not-found-container {
    .lead-not-found-legend {
        text-align: center;
    }
}

.lead-not-found-container,
.lead-wrapper {
    width: 100%;
    min-height: 100vh;
    background: transparent
        radial-gradient(
            closest-side at 25% 52%,
            #461c7c 0%,
            #1c163f 50%,
            #190a20 100%
        )
        0% 0% no-repeat padding-box;
    color: white;
}

.lead-content {
    .lead-logo {
        height: 50px;
        margin-bottom: 30px;
    }
    .lead-img {
        max-height: 500px;
    }
    .lead-form {
        width: 100%;
        color: #505050;
        background: transparent
            linear-gradient(180deg, #bb88ff8f 0%, #3e205500 100%) 0% 0%
            no-repeat padding-box;
        border-radius: 20px;
        padding: 35px;
        display: flex;
        flex-direction: column;
        display: flex;
        justify-content: center;
        h1 {
            color: #ffffff;
            margin-top: 0;
        }
        .InputLabel {
            color: #ffffff;
            margin-top: 0;
        }
        input {
            color: #ffffff;
            box-sizing: border-box;
        }
        .lead-btn {
            font-family: "Poppins-Bold";
            background: rgb(0, 0, 0);
            background: linear-gradient(
                174deg,
                rgb(102 63 130) 0%,
                rgb(77, 18, 156) 100%
            );
            width: 100%;
            padding: 10px;
            border-radius: 50px;
            margin-bottom: 30px;
        }
        .lead-btn:hover {
            background: rgb(0, 0, 0);
            background: linear-gradient(
                174deg,
                rgb(84 62 84) 0%,
                rgb(42, 3, 93) 100%
            );
        }
    }

    .lead-response {
        padding: 30px auto;
        .download {
            border-radius: 15px;
            border: 1px solid #dedede;
            padding: 20px;
            width: 60%;
            margin: 0 auto;
            margin-top: 20px;
            h3 {
                font-size: 16px;
                font-weight: 900 !important;
            }
        }
        &.error {
            text-align: center;
            a {
                padding-top: 20px;
                font-weight: 900;
                text-decoration: none;
            }
            svg {
                font-size: 35px;
                color: red;
            }
        }
        &.success {
            text-align: center;
            h3 {
                font-size: 18px;
                font-weight: 500;
                margin-top: 0;
                span {
                    font-weight: 900;
                }
            }
            svg {
                font-size: 45px;
                color: rgb(62, 40, 137);
                margin-bottom: 20px;
            }
            .success-svg {
                height: 50px !important;
            }
        }
    }
    .footer {
        .contact {
            .data {
                img {
                    width: 18px !important;
                }
            }
        }
    }
}

button.compatibility-btn {
    padding: 12px 0px;
    text-transform: none;
    background: transparent linear-gradient(180deg, #663ce6 0%, #331e73 100%) 0%
        0% no-repeat padding-box;
    border-radius: 25px;
    color: #ffffff;
    width: 100%;
}

.lead-recovery-imei-element {
    flex-basis: 100%;
    justify-content: center;

    img {
        width: 350px;
    }
}

.whatsapp-compatibility-image {
    width: 200px;
}

@media screen and (max-width: 899px) {
    .lead-recovery-wrapper {
        margin: 0px 0;
        align-items: center;
    }

    .lead-content {
        .lead-logo {
            height: 35px;
            margin-bottom: 0px;
        }
        .lead-img {
            display: none !important;
        }
        .lead-form {
            padding: 15px;
            &.step-1 {
                input {
                    height: auto !important;
                }
            }
        }
    }

    .responsive-hidden {
        display: none !important;
    }

    .lead-recovery-imei-element {
        img {
            width: 250px;
        }
    }

    .whatsapp-compatibility-image {
        width: 175px;
    }
}

.lead-form span {
    color: #ffffff;
}

/* Estilos para los Radio */
.radio-input {
    span svg:first-child path {
        color: #ffffff;
    }
    svg:last-child path {
        color: #ff28df;
    }
}

.lead-content {
    .lead-form {
        &.step-1 {
            input {
                height: auto !important;
            }
        }
    }
}

.lead-content {
    .lead-flow {
        input {
            color: #030000;
        }
    }
}

/* IMEI Responses  */
.lead-recovery-imei-section {
    &.centered {
        text-align: center;
    }
    .centered {
        width: 100%;
        text-align: center;
    }
    &.response-purple {
        display: flex;
        align-items: center;
        justify-items: center;
        align-content: center;
        justify-content: center;
        flex-wrap: wrap;
        color: #ffffff;

        button.return-link {
            font-family: v.$poppins-semibold;
            color: #dd49e8;
            &:hover,
            &:focus,
            &:active,
            &:visited,
            &:link {
                background-color: transparent;
            }
        }

        img,
        button.return-link {
            flex-basis: 100%;
            flex-grow: 1;
        }
    }
}

.lead-recovery-imei-esim {
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    background: #f6f6f6;
    border-radius: 15px;
    .lead-recovery-imei-esim-data {
        flex-grow: 1;
        flex-basis: 100%;
        justify-content: center;
        img {
            width: auto;
            height: 60px;
            max-height: none;
            min-height: 0;
        }
        p {
            text-align: center;
        }
    }
}

.phone-compatibility-image {
    max-width: 20px !important;
}
