.container-invoice-your-prepaid {
    .container-info-product {
        .img-invoice {
            width: 246px;
            height: 240px;
            position: absolute;
            margin-top: -210px;
            margin-left: 270px;
        }
    }
}
