@use "purchase_config" as config;

.container-purchase {
    .purchase-image {
        width: 90%;
        height: auto;
    }

    .terms-conditions-purchase {
        font-weight: 500;
        a {
            text-decoration: none;
            font-weight: 600;
            color: config.$text-color-pink-links;
        }
        a:hover {
            color: #d827b5 !important;
        }
    }
}
