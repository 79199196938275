/*** Variables ***/
.container-card-no-plans .card-no-plans {
    box-shadow:
        rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    color: white;
    border-radius: 20px;
    position: relative;
    z-index: 2;
}
.container-card-no-plans .card-no-plans-best-selling {
    border: solid 4px rgb(255, 0, 242);
    animation: myanim 3s infinite;
    padding-top: 2rem;
    padding-bottom: 0.5rem;
}
.container-card-no-plans .card-no-plans-best-selling .title-best-selling {
    background-color: rgb(255, 0, 242);
    animation: myanim 3s infinite;
    border-radius: 15px;
}
.container-card-no-plans .card-no-plans a {
    text-decoration: none;
}
.container-card-no-plans .card-no-plans a .btn-card {
    color: white;
    background-color: #f761ff;
    padding: 10px;
    border-radius: 20px;
    transition: all 0.5s ease;
    font-weight: bold;
}
.container-card-no-plans .card-no-plans a .btn-card:hover {
    background-color: #a17fff;
}
.container-card-no-plans .circular-icon-no-plans {
    background-color: #fc2bdc;
    border-radius: 50%;
    padding: 3px;
}
.container-card-no-plans .img-social-media-card-no-plans {
    width: 300px;
    height: 170px;
}
.container-card-no-plans .img-shadow-card-no-plans {
    position: absolute;
    mix-blend-mode: multiply;
    bottom: -47px;
    z-index: 1;
    width: 300px;
}
@media (min-width: 900px) {
    .container-card-no-plans .img-shadow-card-no-plans {
        width: 350px;
        bottom: -45px;
    }
}


