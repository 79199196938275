/*** Variables ***/
.add-phone-instruction-container {
    line-height: 26px;
}
.add-phone-instruction-container span {
    font-weight: bold;
    color: #ff00dd;
}
.add-phone-instruction-container .required-incidence {
    font-weight: 500 !important;
    color: red !important;
    font-size: 15px;
    margin: 0;
}


