.gift-verification-container .font-medium {
    font-weight: 600;
}
.gift-verification-container .verification-form {
    margin-top: 29px;
}
.gift-verification-container .verification-form input[id^="inputVC-"] {
    width: 30px !important;
    height: 30px;
}
.gift-verification-container .verification-form div[status] {
    display: none;
}
.gift-verification-container .verification-form .sc-qRuZU {
    display: flex;
    justify-content: space-between;
}
.gift-verification-container
    .verification-form
    .sc-qRuZU
    input[id^="inputVC-"] {
    margin-left: 0;
    margin-right: 0;
}
.gift-verification-container .error-verification .error-title {
    display: flex;
    align-items: center;
    font-size: 11px;
    color: #ec0000;
}
.gift-verification-container .error-verification .error-forward {
    font-size: 14px;
    cursor: pointer;
}
@media screen and (max-width: 899px) {
    .gift-verification-container .error-verification .error-title {
        color: #fff;
    }
    .gift-verification-container .error-verification .error-forward {
        color: #a361ff;
    }
}
.gift-verification-container .pointer {
    cursor: pointer;
}


