.gift-welcome-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    gap: 200px;
    padding: 16px;
}
.gift-welcome-container .gift-image {
    display: none;
}
.gift-welcome-container .font-bold {
    font-family: "Poppins-bold";
}
.gift-welcome-container .font-medium {
    font-family: "Poppins-Medium";
}
.gift-welcome-container .font-semibold {
    font-family: "Poppins-SemiBold";
}
.gift-welcome-container .purple-color {
    color: #a361ff;
}
.gift-welcome-container .white-color {
    color: #fff;
}
.gift-welcome-container .img-container {
    width: auto;
}

@media screen and (max-width: 899px) {
    .gift-welcome-container {
        flex-direction: column !important;
        padding: 0px !important;
        margin: 0px !important;
        height: 100vh;
        font-family: "Poppins-Regular";
    }
    .gift-welcome-container .gift-image {
        display: block;
    }
    .gift-welcome-container .logo-dalefon {
        display: none;
    }
    .check-email-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        background: transparent
            linear-gradient(184deg, #200340 0%, #2d0246 100%) 0% 0% no-repeat
            padding-box;
        text-align: center;
    }
    .img-container {
        display: none;
    }
    .movil-container {
        height: 100vh;
        background: transparent
            linear-gradient(184deg, #200340 0%, #2d0246 100%) 0% 0% no-repeat;
    }
}


