.no-data-container {
    .no-data-image {
        width: 250px;
    }
    .no-data-text-container {
        h1 {
            font-family: "Poppins-Bold";
        }
        .no-planes-image {
            height: 30px;
            margin: 0 0 0 5px;
        }
    }
}
