/*** FONTS ***/

/* Poppins FONT*/

@font-face {
    font-family: "Poppins-Bold";
    src: url("../fonts/poppins/Poppins-Bold.otf");
    src:
        local("Poppins Bold"),
        local("Poppins-Bold"),
        url("../fonts/poppins/Poppins-Bold.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-BoldItalic";
    src: url("../fonts/poppins/Poppins-BoldItalic.otf");
    src:
        local("Poppins BoldItalic"),
        local("Poppins-BoldItalic"),
        url("../fonts/poppins/Poppins-BoldItalic.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/poppins/Poppins-Italic.otf");
    src:
        local("Poppins Italic"),
        local("Poppins-Italic"),
        url("../fonts/poppins/Poppins-Italic.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/poppins/Poppins-Italic.otf");
    src:
        local("Poppins Italic"),
        local("Poppins-Italic"),
        url("../fonts/poppins/Poppins-Italic.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-ExtraLight";
    src: url("../fonts/poppins/Poppins-ExtraLight.otf");
    src:
        local("Poppins Extra Light"),
        local("Poppins-ExtraLight"),
        url("../fonts/poppins/Poppins-ExtraLight.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-Light";
    src: url("../fonts/poppins/Poppins-Light.otf");
    src:
        local("Poppins Light"),
        local("Poppins-Light"),
        url("../fonts/poppins/Poppins-Light.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-Medium";
    src: url("../fonts/poppins/Poppins-Medium.otf");
    src:
        local("Poppins Medium"),
        local("Poppins-Medium"),
        url("../fonts/poppins/Poppins-Medium.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-MediumItalic";
    src: url("../fonts/poppins/Poppins-MediumItalic.otf");
    src:
        local("Poppins Medium Italic"),
        local("Poppins-MediumItalic"),
        url("../fonts/poppins/Poppins-MediumItalic.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-Regular";
    src: url("../fonts/poppins/Poppins-Regular.otf");
    src:
        local("Poppins Regular"),
        local("Poppins-Regular"),
        url("../fonts/poppins/Poppins-Regular.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-SemiBold";
    src: url("../fonts/poppins/Poppins-SemiBold.otf");
    src:
        local("Poppins SemiBold"),
        local("Poppins-SemiBold"),
        url("../fonts/poppins/Poppins-SemiBold.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-ExtraBold";
    src: url("../fonts/poppins/Poppins-ExtraBold.otf");
    src:
        local("Poppins ExtraBold"),
        local("Poppins-ExtraBold"),
        url("../fonts/poppins/Poppins-ExtraBold.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-ExtraBoldItalic";
    src: url("../fonts/poppins/Poppins-ExtraBoldItalic.otf");
    src:
        local("Poppins ExtraBoldItalic"),
        local("Poppins-ExtraBoldItalic"),
        url("../fonts/poppins/Poppins-ExtraBoldItalic.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-Black";
    src: url("../fonts/poppins/Poppins-Black.otf");
    src:
        local("Poppins Black"),
        local("Poppins-Black"),
        url("../fonts/poppins/Poppins-Black.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-BlackItalic";
    src: url("../fonts/poppins/Poppins-BlackItalic.otf");
    src:
        local("Poppins Black Italic"),
        local("Poppins-BlackItalic"),
        url("../fonts/poppins/Poppins-BlackItalic.otf") format("opentype");
}

/* Fonts variable */

$poppins-bold: Poppins-Bold, Arial, Helvetica, sans-serif;
$poppins-extrabold: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
$poppins-extralight: Poppins-ExtraLight, Arial, Helvetica, sans-serif;
$poppins-light: Poppins-Light, Arial, Helvetica, sans-serif;
$poppins-medium: Poppins-Medium, Arial, Helvetica, sans-serif;
$poppins-medium-italic: Poppins-MediumItalic, Arial, Helvetica, sans-serif;
$poppins-regular: Poppins-Regular, Arial, Helvetica, sans-serif;
$poppins-semibold: Poppins-SemiBold, Arial, Helvetica, sans-serif;
$poppins-bold-italic: Poppins-BoldItalic, Arial, Helvetica, sans-serif;
$poppins-extrabold-italic: Poppins-ExtraBoldItalic, Arial, Helvetica, sans-serif;
$poppins-black: Poppins-Black, Arial, Helvetica, sans-serif;
$poppins-black-italic: Poppins-BlackItalic, Arial, Helvetica, sans-serif;
