@use "add-phone_config" as config;

.add-phone-instruction-container {
    line-height: 26px;
    span {
        font-weight: bold;
        color: config.$text-color-pink-links;
    }
    .required-incidence {
        font-weight: 500 !important;
        color: red !important;
        font-size: 15px;
        margin: 0;
    }
}
