.menu-container .logo-image {
    height: auto;
    width: 210px;
}

@media screen and (max-width: 899px) {
    .menu-container {
        background-image: url("assets/images/menu/midalefon.jpg");
        background-size: cover;
        background-position: top center;
        background-repeat: no-repeat;
        font-family: "Poppins-Regular";
    }
}


