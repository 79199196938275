@use "section-carousel-product_config" as config;

.container-section-carousel-product {
    .img-title {
        width: 35px;
        height: 35px;
        @media (min-width: config.$breakpoint-md) {
            width: 50px;
            height: 50px;
        }
    }

    .swiper {
        width: 100%;
        padding-top: 25px;
        padding-bottom: 50px;
    }

    .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 300px;
        height: "auto";
        @media (min-width: config.$breakpoint-md) {
            width: 350px;
        }
    }

    .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        margin-left: 10px !important;
        margin-right: 10px !important;
        background-color: #b5a8bd;
    }

    .swiper-pagination-bullet-active {
        background-color: #ffffff;
    }

    .terms-and-conditions p {
        color: rgb(175, 175, 175);
    }

    .terms-and-conditions a {
        text-decoration: none;
        color: config.$text-color-pink-links;
    }
}
